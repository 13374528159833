//*****
//*
//      Structure of framework Mixin SCSS
//*
//*****


// ******************************
// Import scss files you need!!!
// ******************************

@import "~@beatroot/web-styles-utils-nodejs/layout/colors";
@import "../config";

// ******************************
// end of the imported scss files
// ******************************


.app {
  display: flex;
  justify-content: flex-start;
  flex: auto;
  flex-direction: column;
  overflow: hidden;
}
.main{
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100vw;
  height: 100%;
  flex: auto;
}
.loading{
  display: flex;
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: $yellow;

  .loading-primary{
    color: $primary-color;
    padding: $offset-m $offset-l;
    font-size: $large;
    font-weight: $font-semibold;
  }
}