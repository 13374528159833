//*****
//*
//      Structure of the component SCSS
//*
//*****

// ******************************
// Import scss files you need!!!
// ******************************

@import "~@beatroot/web-styles-utils-nodejs/partials/_base";
@import "~@beatroot/web-styles-utils-nodejs/lib/btns";
@import "~@beatroot/web-styles-utils-nodejs/layout/colors";
@import "../config";

// ******************************
// end of the imported scss files
// ******************************


%btnRegular {
  cursor: pointer;
  border-color: transparent;
  border-radius: $border-radius;

  :global(.svg-inline--fa) {
    padding-right: $offset-s;
  }
  &:focus{
    outline: none;
  }
}

button{
  line-height: normal;
}

%btnMain{
  @extend %btn;
  background-color: $default-color;
  padding: 0 $offset-btn;
  height: 38px;
  margin: $offset-m;
  font-size: $regular;
  font-weight: $font-regular;
  color: $txt-color;
  border: $border-width solid transparent;
  border-radius: $border-radius;
  text-decoration: none;
  cursor: pointer;

  .fa,
  svg{
    font-size: $regular;
    margin: 0 $offset-m;
  }
}
.iconGroup{
  padding: 0 $offset-l 0 0;

  &.primary{
    &:hover{
      .txt{
        border-left-color: $primary-color;
      }
    }
    .txt{
      border-left-color: darken($primary-color, 10%);
    }
  }
  &.secondary{
    &:hover{
      .txt{
        border-left-color: $secondary-color;
      }
    }
    .txt{
      border-left-color: darken($secondary-color, 10%);
    }
  }
  .txt{
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 $offset-xl;
    border-left: $border-width solid;

  }
  .fa{
    margin: 0 $offset-xl;

  }
}
.primary{
  @extend %btnMain;
  background-color: $primary-color;
  color: $white;

  &:hover{
    border-color: transparent;
    background-color: darken($primary-color, 10%);
    color: $white;
    text-decoration: none;
  }
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(.disabled):active,
  &:focus,
  &:active,
  &.active{
    background-color: darken($primary-color, 10%);
    border-color: transparent;
    color: $white;
    cursor: default;
    text-decoration: none;
    box-shadow: none;
    outline: none;

    &:hover{
      border-color: transparent;
    }
  }
  &.disabled,
  &[disabled],
  &:disabled{
    cursor: default;
    color: $txt-color;
    background-color: $default-color;
    box-shadow: none;
  }
}
.secondary {
  @extend %btnMain;
  background-color: $secondary-color;
  color: $white;

  &:hover{
    background-color: darken($secondary-color, 10%);
    border-color: transparent;
    color: $white;
    text-decoration: none;
  }
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(.disabled):active,
  &:focus,
  &:active,
  &.active{
    background-color: darken($secondary-color, 10%);
    color: $white;
    border-color: transparent;
    cursor: default;
    text-decoration: none;
    box-shadow: none;
    outline: none;
  }
  &.disabled,
  &[disabled],
  &:disabled{
    cursor: default;
    color: $txt-color;
    background-color: $white;
    box-shadow: none;
  }
}
.icon{
  background: none;
  border: none;
  color: $txt-color;
  font-size: $title;
  text-decoration: none;
  cursor: pointer;
  filter: brightness(0) invert(1);

  &:hover{
    color: $primary-color;
    background-color: transparent;
  }
  &:disabled{
    cursor: not-allowed;
    background-color: transparent;
    color: darken($default-color, 10%);

    img{
      filter: brightness(0) invert(1);
    }
  }
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(.disabled):active,
  &:active,
  &:focus{
    color: $primary-color;
    border: none;
    background-color: transparent;
    box-shadow: none;
    outline: none;
  }
  &.color-primary{
    color: $primary-color;
  }
  &.iconSmall{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 0;
    background-color: transparent;
    border-color: $primary-color;

    &:focus,
    &:active,
    &:hover{
      color: darken($default-color, 10%);
    }
    &.color-primary{
      color: $primary-color;

      &:hover{
        color: darken($primary-color, 20%);
      }
    }
  }

  img{
    &:hover{
      filter: brightness(1) invert(1);
    }
  }
}
.link{
  @extend %btnMain;
  background-color: transparent;
  box-shadow: none;
  margin: 0;
  padding: 0 $offset-m;
  border-radius: 0;
  color: $txt-color;

  &:hover{
    color: $default-color;
    background-color: transparent;
    box-shadow: none;
    border-color: transparent;
  }
  &.active,
  &:focus,
  &:active{
    color: $primary-color;
    background-color: transparentize($white, .7);
  }
  &.disabled,
  &[disabled],
  &:disabled{
    cursor: default;
    color: $txt-color;
    box-shadow: none;
  }
  .primary{
    color: $primary-color;

    &:hover {
      color: darken($primary-color, 20%);
    }
  }
}
.btnDropdown{
  @extend .link;

  &:hover{
    &:after{
      content: '';
      position: absolute;
      left: 15px;
      top: 40%;
      width: 0;
      height: 0;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-left: 4px solid $primary-color;
      clear: both;
    }
  }
}
