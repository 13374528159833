//*****
//*
// Font family default Nunito
//*
//*****
// Import Font from Google Font
//*****

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;600;700;900&display=swap');

// ******************************


$font-family: 'Nunito', sans-serif !default;

$font-light: 200 !default;
$font-regular: 400 !default;
$font-semibold: 600 !default;
$font-bold: 700 !default;
$font-black: 900 !default;