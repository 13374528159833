//*****
//*
// Theme typography SCSS
//*
//*****

//Normal sizing

$header-l: 120px !default;
$header: 80px !default;
$title-l: 65px !default;
$title: 50px !default;
$subtitle-l: 40px !default;
$subtitle: 30px !default;
$large: 26px !default;
$semi-large: 20px !default;
$regular: 16px !default;
$small: 14px !default;
$tiny: 12px !default;
