// ******************************
// Import scss files you need!!!
// ******************************

@import "~@beatroot/web-styles-utils-nodejs/partials/_base";
@import "~@beatroot/web-styles-utils-nodejs/layout/colors";
@import "../../web-styles/fonts/lato";
@import "../../web-styles/config";

// ******************************
// end of the imported scss files
// ******************************


.main{
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  flex: auto;
  background: #161823;
}

