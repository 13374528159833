//*****
//*
// Font family default Staatliches
//*
//*****
// Import Font from Google Font
//*****

@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');

// ******************************


$font-family: 'Staatliches', cursive !default;

$font-regular: 400 !default;