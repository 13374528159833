// ******************************
// Import scss files you need!!!
// ******************************

//  -------- STRUCTURE --------

@import "~@beatroot/web-styles-utils-nodejs/partials/_base";
@import "../config";

// ******************************
// end of the imported scss files
// ******************************


.noMarkets{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: auto;
  width: 100%;
  height: 100%;
  padding: $offset-l;
  background-color: $white;
  box-sizing: border-box;

  .container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -300px;
    flex: auto;

    .title{
      margin: auto;
      text-align: center;
      font-size: $title;
      font-weight: $font-semibold;
      color: $txt-color;
    }
  }
}

/* NOT FOUND */

.notFound {
  display: flex;
  flex: auto;
  width: 100%;
  align-items: center;
  background: darken(#eee, 10%);
  padding: 10px;
  color: #FF0000 !important;
  font-size: 18px;
  text-shadow: 1px 1px 1px #000;
  justify-content: center;
}
