//*****
//*
// Theme colors SCSS
//*
//*****

//* -------------- Default B&W colors -------------- *//

$black: #000 !default;
$white: #fff !default;

//* -------------- Default Primary & Secondary colors -------------- *//

$primary-color: #FE2C55 !default;
$secondary-color: #161823 !default;

//* -------------- Framework colors -------------- *//

$title-color: #8A8B90 !default;
$label-color: #D0D0D0 !default;
$txt-color: #fff !default;

$default-color: #161823 !default;
$bg-color: #fff !default;