//*****
//*
// Theme colors SCSS
//*
//*****

// ******************************
// Import scss files you need!!!
// ******************************

@import "~@beatroot/web-styles-utils-nodejs/partials/_base";

// ******************************
// end of the imported scss files
// ******************************

body{
    &.dark-template{
        .logo{
            @include contain-background('../media/img/logo-white.png');
        }
    }
    &.white-template{
        .logo{
            @include contain-background('../media/img/logo.png');
        }
    }
}
