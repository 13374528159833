﻿//*****
//*
// All the border radius and width SCSS
//*
//*****


// ----- Btns Border width Section

$border-width: 1px !default; //Border width for the all buttons
$border-width-avatar: 2px !default; //Border width for the all buttons
$border-width-header: 4px !default; //Border width used in TV Odds on header of each sport category
$border-tab: 6px !default; //Border width used in TV Odds on header of each sport category

// ----- Btns Border Radius Section

$border-radius: 12px !default; // Border radius for the all buttons
$border-radius-cb: 8px !default; // Border radius for the all buttons
$border-radius-circle: 50% !default; //Border radius for Circles

