// ******************************
// Import scss files you need!!!
// ******************************

@import "~@beatroot/web-styles-utils-nodejs/partials/base";
@import "~@beatroot/web-styles-utils-nodejs/layout/colors";
@import "../../../web-styles/fonts/lato";
@import "../../../web-styles/config";

// ******************************
// end of the imported scss files
// ******************************

// the screen of the phone
.wallpaper {
  aspect-ratio: 6 / 13;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;

  padding-left: 4px;
  padding-top: 1px;

  background-repeat: no-repeat;

  &.react-player {
    position: relative;
    // transition: all 1s ease-in-out 1s;
  }
}

// Device phone look styles
.phone-device {
  display: inline-block;
  position: relative;
  box-sizing: content-box !important;

  .screen {
    aspect-ratio: 6 / 13;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 3;
    overflow: hidden;
    display: block;
    box-shadow: 0 0 0 3px #111;
    transition: opacity 750ms cubic-bezier(0.645, 0.045, 0.355, 1);
    border-radius: 25px;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
  }

  &.iphone-x {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 812px;
    width: 375px;
    aspect-ratio: 6 / 13;
    border-radius: 25px;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
    background: transparentize(#161823, 0.3);
    position: relative;
    // background: transparentize($black, 0.8);
    // background: #161823;
    // transition: all 2s ease-in-out 500ms;
    // color: #f2f2f2;

    // :before,
    // &:after {
    //   content: "";
    //   position: absolute;
    //   top: 2px;
    //   left: 2px;
    //   background: linear-gradient(45deg, #fb0094, #00ff00, #ffff00, #ff0000, #fb0094, #00ff00, #ffff00, #ff0000);
    //   background-size: 400%;
    //   z-index: -1;
    //   animation: shadow 20s linear infinite;
    //   border-radius: 40px;
    // }
    // &:after {
    //   top: 15%;
    //   left: -10px;
    //   width: 50%;
    //   height: 50%;
    //   border-radius: 40%;
    //   filter: blur(24px);
    //   opacity: 0.9;
    // }
    // &:before {
    //   top: 40%;
    //   left: 52%;
    //   width: 50%;
    //   height: 40%;
    //   border-radius: 50%;
    //   filter: blur(24px);
    //   opacity: 0.9;
    // }

    &:before,
    &:after {
      content: "";
      position: absolute;
      background: linear-gradient(45deg, #fb0094, #00ff00, #ffff00, #ff0000, #fb0094, #00ff00, #ffff00, #ff0000);
      background-size: 400%;
      z-index: -1;
      animation: shadow 20s linear infinite;
      border-radius: 20px;
      -moz-border-radius: 20px;
      -webkit-border-radius: 20px;
    }
    &:after {
      top: 5%;
      left: 5%;
      width: 90%;
      height: 90%;
      border-radius: 20%;
      filter: blur(50px);
      opacity: 0.9;
    }

    // &:before {
    //   top: 30%;
    //   left: 25%;
    // width: 70%;
    // height: 60%;
    //   border-radius: 20%;
    //   filter: blur(50px);
    //   opacity: 0.9;
    // }

    @keyframes shadow {
      0% {
        background-position: 0 0;
      }
      50.01% {
        background-position: 200% 0;
      }
      100% {
        background-position: 0 0;
      }
    }
    .screen {
      box-shadow: none;
    }
  }
}

@media all and (min-width: 1000px) and (max-width: 1800px) {
  .phone-device {
    .screen {
      border-radius: 25px;
      -moz-border-radius: 25px;
      -webkit-border-radius: 25px;
    }

    &.iphone-x {
      width: 338px;
      height: 735px;
      border-radius: 25px;
      -moz-border-radius: 25px;
      -webkit-border-radius: 25px;
    }
  }
  .wallpaper {
    border-radius: 25px;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
  }
}

// @media all and (min-width: 1000px) and (max-width: 1200px) {
//   .phone-device {
//     .screen {
//       border-radius: 25px;
//       -moz-border-radius: 25px;
//       -webkit-border-radius: 25px;
//     }

//     &.iphone-x {
//       width: 338px;
//       height: 735px;
//       border-radius: 25px;
//       -moz-border-radius: 25px;
//       -webkit-border-radius: 25px;
//     }
//   }
//   .wallpaper {
//     border-radius: 25px;
//     -moz-border-radius: 25px;
//     -webkit-border-radius: 25px;
//   }
// }

@media all and (min-width: 640px) and (max-width: 1000px) {
  .phone-device {
    .screen {
      border-radius: 20px;
      -moz-border-radius: 20px;
      -webkit-border-radius: 20px;
    }

    &.iphone-x {
      width: 274px;
      height: 600px;
      border-radius: 20px;
      -moz-border-radius: 20px;
      -webkit-border-radius: 20px;
    }
  }

  .wallpaper {
    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    padding-left: 0;
    padding-top: 0;
  }
}

@media all and (min-width: 450px) and (max-width: 640px) {
  // .phone-device.iphone-x {
  //   width: 375px;
  //   height: 525px;
  //   border-radius: 10px;
  // }
  // .wallpaper {
  //   padding-left: 0px;
  //   padding-top: 0px;
  //   border-radius: 10px;
  // }

  .phone-device {
    .screen {
      border-radius: 10px;
      -moz-border-radius: 10px;
      -webkit-border-radius: 10px;
    }

    &.iphone-x {
      width: 240px;
      height: 525px;
      border-radius: 10px;
      -moz-border-radius: 10px;
      -webkit-border-radius: 10px;

      &:after {
        content: "";
        position: absolute;
        background: linear-gradient(45deg, #fb0094, #00ff00, #ffff00, #ff0000, #fb0094, #00ff00, #ffff00, #ff0000);
        background-size: 400%;
        z-index: -1;
        animation: shadow 20s linear infinite;
        border-radius: 20px;
      }
      &:after {
        top: 1%;
        left: 1%;
        width: 70%;
        height: 70%;
        border-radius: 20%;
        filter: blur(30px);
        opacity: 0;
      }
    }
  }

  .wallpaper {
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    padding-left: 1px;
    padding-top: 0;
  }
}

@media all and (max-width: 450px) {
  // .phone-device {
  //   &.iphone-x {
  //     &:after {
  //       top: 15%;
  //       left: 0;
  //     }
  //     &:before {
  //       top: 40%;
  //       left: 45%;
  //     }
  //   }
  // }

  // .phone-device {
  //   &.iphone-x {
  //     &:before {
  //       border-radius: 15px;
  //       -moz-border-radius: 15px;
  //       -webkit-border-radius: 15px;
  //     }
  //   }
  // }

  .phone-device {
    .screen {
      width: 158px;
      height: 340px;
      border-radius: 15px;
      -moz-border-radius: 15px;
      -webkit-border-radius: 15px;
    }

    &.iphone-x {
      width: 158px;
      height: 340px;
      border-radius: 15px;
      -moz-border-radius: 15px;
      -webkit-border-radius: 15px;

      &:after {
        top: 5px;
        left: 5px;
        // width: 70%;
        // left: 70%;
      }
    }
  }

  .wallpaper {
    border-radius: 15px;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    padding-left: 3px;
    // padding-top: 1px;
  }
}

.fadeIn {
  -webkit-animation: fadeIn 700ms ease-in; /* Chrome, Safari, Opera */
  animation: fadeIn 700ms ease-in;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Standard syntax */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeInDown {
  -webkit-animation: fadeInDown 500ms ease-in-out; /* Chrome, Safari, Opera */
  animation: fadeInDown 500ms ease-in-out;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

/* Standard syntax */
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

// .fadeinUp {
//   -webkit-animation: fadeInUp 500ms ease-in-out; /* Chrome, Safari, Opera */
//   animation: fadeInUp 500ms ease-in-out;
// }

// /* Chrome, Safari, Opera */
// @-webkit-keyframes fadeInUp {
//   0% {
//     opacity: 0;
//     -webkit-transform: translateY(40px);
//   }
//   100% {
//     opacity: 1;
//     -webkit-transform: translateY(0);
//   }
// }

// /* Standard syntax */
// @keyframes fadeInUp {
//   0% {
//     opacity: 0;
//     transform: translateY(40px);
//   }
//   100% {
//     opacity: 1;
//     transform: translateY(0);
//   }
// }

// .fadeInLeft {
//   -webkit-animation: fadeInLeft 500ms ease-in-out; /* Chrome, Safari, Opera */
//   animation: fadeInLeft 500ms ease-in-out;
// }

// /* Chrome, Safari, Opera */
// @-webkit-keyframes fadeInLeft {
//   0% {
//     opacity: 0;
//     -webkit-transform: translateX(80px);
//   }
//   100% {
//     opacity: 1;
//     -webkit-transform: translateY(0);
//   }
// }

// /* Standard syntax */
// @keyframes fadeInLeft {
//   0% {
//     opacity: 0;
//     transform: translateX(80px);
//   }
//   100% {
//     opacity: 1;
//     transform: translateY(0);
//   }
// }

// .fadeInRight {
//   -webkit-animation: fadeInRight 500ms ease-in-out; /* Chrome, Safari, Opera */
//   animation: fadeInRight 500ms ease-in-out;
// }

// /* Chrome, Safari, Opera */
// @-webkit-keyframes fadeInRight {
//   0% {
//     opacity: 0;
//     -webkit-transform: translateX(-80px);
//   }
//   100% {
//     opacity: 1;
//     -webkit-transform: translateY(0);
//   }
// }

// /* Standard syntax */
// @keyframes fadeInRight {
//   0% {
//     opacity: 0;
//     transform: translateX(-80px);
//   }
//   100% {
//     opacity: 1;
//     transform: translateY(0);
//   }
// }
