// ******************************
// Import scss files you need!!!
// ******************************

@import "~@beatroot/web-styles-utils-nodejs/partials/_base";
@import "~@beatroot/web-styles-utils-nodejs/layout/colors";
@import "../../../web-styles/fonts/lato";
@import "../../../web-styles/config";

// ******************************
// end of the imported scss files
// ******************************

//-------------- Classes --------------
$symbol-height: 100px;
$symbol-width: 200px;

//-------------- Mixins --------------
@mixin display-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin symbol {
  @include display-center;
  width: $symbol-width;
  height: $symbol-height;
  @include contain-background("../../../web-styles/media/img/logo-simple-white.png");
}
@mixin txt-staatliches {
  font-family: "Staatliches", sans-serif;
  text-align: center;
  font-weight: $font-regular;
  color: $txt-color;
  text-transform: uppercase;
}

//-------------- Default shared CSS --------------
.section {
  @include display-center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: relative;
  padding: 100px 0;
}

//-------------- Pages --------------

// --- Page 1

.video {
  // height: 100vh;
  height: 100%;
  position: absolute;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}

.welcome-area {
  justify-content: space-between;
  // padding-top: $offset-xxl;
  padding-top: 0;
  padding-bottom: 0;
  z-index: 5;
  // @include cover-background("../../../web-styles/media/img/bg.jpg");
  background: transparent;

  .header {
    display: flex;
    justify-content: space-between;
    margin: $offset-xl 0;
    width: 100%;
    padding: 50px 4% 4% 4%;
    z-index: 100;

    .logo {
      @include display-center;
      width: 220px;
      height: 36px;
      @include contain-background("../../../web-styles/media/img/logotype.png");
    }
    .btn {
      @include display-center;
      padding: $offset-xl $offset-xxxl;
      color: transparentize($txt-color, 0.1);
      background: $primary-color;
      font-size: $small;
      font-weight: $font-bold;
      border-radius: $border-radius-cb;
      text-transform: uppercase;
      cursor: pointer;
      border-color: transparent;

      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  .bodyW {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    // padding-bottom: 1%;

    .symbol {
      @include symbol;
      margin-top: 10%;
    }
    .title {
      @include txt-staatliches;
      font-size: $header-l;
    }
    .subtitle {
      @include txt-staatliches;
      font-size: $title;
    }

    .discover-area {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 1%;
      margin-top: 4%;
      border-top: 1px solid transparentize($txt-color, 0.5);

      .title {
        @include txt-staatliches;
        font-size: $title;
        color: $primary-color;
      }
      .btn-group {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2%;
      }
      .txt {
        text-align: center;
        color: $txt-color;
        width: 90%;
        margin-left: 10%;
      }
    }
  }
}

// --- Page 2

//.discover-area{
//background: #161823;
//z-index: 4;
//padding: 0;

//.parralax{
//  @include display-center;
//  align-items: flex-end;
//  position: relative;
//  overflow: hidden;
//  width: 100vw;
//  height: 100vh;
//
//  img{
//    top: 0;
//  }
//}
//}

// --- Page 3

.video-area {
  // background: linear-gradient(180deg, #161823 100vh,  #2b1d75 100vh, #8725de 200vh,  #de1bff 300vh, $white 300vh, $white 400vh, #222 400vh, #111 100%);
  background: #161823;
  z-index: 2;
  height: auto;
  padding: 0;
  min-height: 100vh;
  position: relative;

  .background {
    display: flex;
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 60%;
    left: 0;
    z-index: 5;

    .react-player {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.3;
      filter: blur(10px);
    }
  }
  .txt {
    color: $txt-color;
    margin-bottom: $offset-xxxl;
  }
  .aside {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    z-index: 10;
    height: 100vh;
    transition: all 0.8s ease 0s;

    &.positionFixed {
      display: flex;
      position: fixed;
      top: 0;
    }
    &.positionAbsolute {
      position: absolute;

      &.asideBottom {
        bottom: 0;
        top: unset;
      }
      &.asideTop {
        top: 0;
      }
    }

    .bg-mobile {
      display: none;
    }
    .phone-section {
      display: flex;
      flex-direction: column;

      .wrapper {
        height: 100%;
      }
    }
  }
  .wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: 100vh;
    position: relative;
    z-index: 10;

    &.center {
      justify-content: center;
    }
    .content {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: $offset-xxxl 0;
    }

    #relay {
      padding-bottom: 10%;
    }

    //  Color texts
    :global(#discoverContent) {
      .title {
        color: $primary-color;
      }
    }
    // :global(#soloContent){
    //   .title{
    //     color: $default-color;
    //   }
    // }
    // :global(#duoContent){
    //   .txt,
    //   .subtitle{
    //     color: $default-color;
    //   }
    // }
  }
}

// --- Page 4

.footerSection {
  width: 100vw;
}

.preFooter {
  height: 25vh;
  width: 100%;
}

.footer {
  @include display-center;
  position: relative;
  height: auto;
  padding-bottom: 0;
  flex-direction: column;
  z-index: 10;
  background: linear-gradient(84deg, rgba(213, 38, 72, 1) 0%, rgba(255, 51, 85, 1) 50%, rgba(227, 16, 57, 1) 100%);

  .symbol {
    @include symbol;
  }

  .symbol-absolute {
    position: absolute;
    top: -50px;
    margin: auto;
    z-index: 99;
  }
  .txt {
    font-family: "Lato", sans-serif;
    text-align: center;
    font-size: $title-l;
    font-weight: $font-semibold;
    color: $txt-color;
    // margin: $offset-xxxl 0;
  }
  .form {
    @include display-center;
    width: 100%;
    // margin: 10% 0;
  }
  .btn-group {
    margin: $offset-xxl 0;
  }
}

//-------------- Elements --------------

.title {
  font-family: "Lato", sans-serif;
  font-size: $title;
  color: $primary-color;
  font-weight: $font-bold;
  margin-bottom: $offset-xxl;
  //text-transform: uppercase;
}
.txt {
  font-family: "Lato", sans-serif;
  color: $default-color;
  font-size: $semi-large;
  font-weight: $font-semibold;
}
.btn-group {
  display: flex;
  margin: $offset-xl 0;

  button {
    &:last-child {
      padding-left: 0;
    }
  }
}
.separator {
  @include display-center;
  margin: 4% 0;
  width: 90%;
  height: 1px;
  background: transparentize($bg-color, 0.8);
}
.links {
  @include display-center;
  font-family: "Lato", sans-serif;
  justify-content: space-evenly;
  font-size: $regular;
}
.copyright {
  @include display-center;
  justify-content: center;

  .symbol {
    height: 20px;
    width: 40px;
  }
  .small-txt {
    font-family: "Lato", sans-serif;
    font-weight: $font-semibold;
    font-size: $small;
  }
}
.media {
  @include display-center;
  justify-content: center;
  margin-top: $offset-l;

  .icon {
    font-size: $semi-large;
    margin-right: $offset-l;
  }
}
.bottom {
  @include display-center;
  flex-direction: column;
  flex: auto;
  width: 100%;
  position: relative;
  padding-top: 5%;

  .goTop {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 8vh;
    right: 5vw;
    width: 40px;
    height: 40px;
    color: $white;
    cursor: pointer;
    transition: all 0.4s ease-in-out 0s;
    background: transparentize($white, 0.8);
    border-radius: 50%;

    svg {
      font-size: $regular;
    }

    &:hover {
      color: $default-color;
      width: 44px;
      height: 44px;
      background: transparentize($white, 0.95);

      svg {
        font-size: $semi-large;
      }
    }
  }
}

//-------------- Media Query --------------
@media all and (min-width: 300px) and (max-width: 1200px) {
  .video-area {
    background: $default-color;

    .aside {
      flex-direction: column;
    }
    .background {
      display: none;
    }
    .aside.positionFixed {
      .bg-mobile {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $default-color;
      }
    }
    .aside {
      &.positionAbsolute.asideBottom {
        bottom: calc(100vh - 450px);
      }
      &.positionFixed {
        left: 0;
        right: 0;
        height: 450px;
        //min-height: 490Px;
        z-index: 999;
      }
    }
    .mobile-video {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .wrapper {
        height: auto;

        &:first-child {
          padding-top: 450px;
        }
        &.center {
          justify-content: flex-end;
        }

        .title {
          color: $primary-color !important;
          font-size: $subtitle;
        }
        .subtitle {
          font-size: $small;
          color: $txt-color !important;
        }
        .txt {
          font-size: $semi-large - 2px;
          color: $txt-color !important;
        }
      }
    }
  }
  .footer {
    padding-bottom: 0;

    .copyright {
      .symbol {
        display: none;
      }
    }

    .symbol {
      top: -30px;
      width: 150px;
      height: 75px;
    }
    .txt {
      font-size: $subtitle;
      margin-bottom: $offset-l;
    }
  }
}

@media all and (min-width: 800px) and (max-width: 1200px) {
  .video-area {
    .aside {
      &.positionFixed {
        left: 0;
        right: 0;
        height: 75vh;
        z-index: 999;
      }
    }
  }
}

@media all and (min-width: 640px) and (max-width: 800px) {
  .video-area {
    .aside {
      &.positionFixed {
        left: 0;
        right: 0;
        height: 70vh;
        z-index: 999;
      }
    }
  }
}

@media all and (min-width: 300px) and (max-width: 640px) {
  .video-area {
    .mobile-video {
      .wrapper {
        &.center {
          &:last-child {
            padding-bottom: 5%;
          }
        }
      }
    }
  }
  .welcome-area {
    // height: 100%;
    background-position: top center;
    background-size: cover;
    overflow: hidden;

    .header {
      .logo {
        width: 100px;
        height: auto;
      }
      .btn {
        @include display-center;
        padding: $offset-l $offset-xl;
      }
    }
    .bodyW {
      padding-top: 20vh;

      .title {
        font-size: $subtitle-l;
        margin-bottom: 0;
      }
      .subtitle {
        font-size: $large;
      }

      .discover-area {
        padding-top: 5%;
        margin-top: 5%;

        .title {
          font-size: $title;
          color: $primary-color;
        }
        .btn-group {
          margin-bottom: 1%;
        }
        .txt {
          color: $txt-color;
          width: 100%;
          padding: 0 5%;
          margin-left: 0;
        }
      }
    }
  }
  .footer {
    height: 70vh;
    padding-top: 20%;

    .copyright {
      flex-direction: column;
      border-right: none;
    }
    .media {
      justify-content: center;
    }
  }
}

.btn-group {
  img {
    max-height: 50px;
  }
}
