// ******************************
// Import scss files you need!!!
// ******************************

@import "~@beatroot/web-styles-utils-nodejs/partials/base";
@import "~@beatroot/web-styles-utils-nodejs/layout/colors";
@import "../../../web-styles/config";

// ******************************
// end of the imported scss files
// ******************************

.navigation{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: $offset-xxxl;
  z-index: 5;

  .shape{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    margin-bottom: $offset-l;
    border: none;
    transition: all .5s ease 0s;

    svg{
      transition: all .5s ease 0s;
      width: 50px;
      height: 45px;

      :global(.icon-bg){
        transition: all .5s ease 0s;
        fill: $white;
      }
      :global(.icon-shape){
        transition: all .5s ease 0s;
        fill: $secondary-color;
      }
    }
    &:active,
    &:focus{
      margin: 0;
      box-shadow: none !important;
      background-color: transparent !important;

      svg{
        width: 50px;
        height: 45px;

        :global(.icon-bg){
          fill: $white;
        }
        :global(.icon-shape){
          fill: $secondary-color;
        }
      }
    }
    &:global(.active){
      background-color: transparent !important;
      border-color: transparent !important;
      outline: none !important;
      box-shadow: none !important;
      margin: calc(2 * #{$offset-xxxl}) 0 calc(3 * #{$offset-xxxl});

      svg{
        width: 80px;
        height: 80px;

        :global(.icon-bg){
          fill: $primary-color;
        }
        :global(.icon-shape){
          fill: $white;
        }
      }
    }
  }
}


//-------------- Media Query --------------

@media all and (min-width: 1201px) {
  .navigation{
    .shape{
      &:hover{
        margin-bottom: $offset-m;
        margin-top: -#{$offset-m};
        box-shadow: none !important;
        background-color: transparent !important;

        svg{
          width: 60px;
          height: 60px;

          :global(.icon-bg){
            fill: $primary-color;
          }
          :global(.icon-shape){
            fill: $white;
          }
        }
      }
    }
  }
}
@media all and (min-width: 300px) and (max-width: 1200px) {

  .navigation{
    flex-direction: row;
    width: 95px;
    margin: 0;

    .shape{
      margin: 0;

      &:active,
      &:global(.active) {
        svg {
          height: 60px;
        }
      }
      svg{
        height: 35Px;
      }
      &:active,
      &:global(.active){
        margin: 0;
      }
    }
  }
}