//*****
//*
// Font family default Lato
//*
//*****
// Import Font from Google Font
//*****

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');

// ******************************


$font-family: 'Lato', sans-serif !default;

$font-light: 100 !default;
$font-regular: 300 !default;
$font-semibold: 400 !default;
$font-bold: 700 !default;