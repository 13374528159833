﻿//*****
//*
// All the margins and paddings SCSS
//*
//*****

// ----- Header Section

//     For margins and paddings:
$offset-s: 2px !default;
$offset-m: 5px !default;
$offset-l: 10px !default;
$offset-xl: 15px !default;
$offset-xxl: 20px !default;
$offset-xxxl: 30px !default;
$offset-btn: 24px !default;
