//*****
//*
//      Body & Html styles
//*
//*****

// ******************************
// Import scss files you need!!!
// ******************************

@import "../config";
@import "../fonts/roboto";

// ******************************
// end of the imported scss files
// ******************************

:global(svg){
  height: 14px;
}

@include respond-above(lg) {
  html{
    font-size: 16Px; //plugin to ignore the px
  }
}

@include respond-below(lg) {
  html{
    font-size: 14Px; //plugin to ignore the px
  }
}

body {
  font: 100% $font-family;
  font-display: optional;
  min-width: 400px;
  margin: 0;
  color: $txt-color;
  background-color: $bg-color;;
}
hr{
  display: flex;
  width: 100%;
  margin-bottom: $offset-l;
  margin-top: $offset-l;
  border: 0;
  height: 1px;
  background: linear-gradient(to left, transparentize($white, 1), transparentize($white, .5), transparentize($white, 1));
}

a {
  color: inherit; /* Inherits the colors for links from the parents */
  text-decoration: inherit; /* no underline */
}

:focus{
  outline: none;
}
