// ******************************
// Import scss files you need!!!
// ******************************

@import "~@beatroot/web-styles-utils-nodejs/partials/base";
@import "~@beatroot/web-styles-utils-nodejs/layout/colors";
@import "../../../web-styles/fonts/lato";
@import "../../../web-styles/config";

// ******************************
// end of the imported scss files
// ******************************

.black{
  color: $txt-color;
  background-color: $default-color;
}
.default{
  color: $default-color;
  background-color: $bg-color;

  .header{
    .txt{
      color: $default-color;
    }
  }
}
.header{
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex: auto;
  box-shadow: transparentize($black, .9) 0 0 20px;
  margin: 0 -#{$offset-xl};
  padding: $offset-l;

  &.hide-header{
    display: none;
  }
  .logo{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 32px;
    @include contain-background('../../../web-styles/media/img/logo-simple.png');
  }
  .txt{
    display: flex;
    padding: $offset-xl $offset-xxxl;
    font-size: $small;
    font-weight: $font-bold;
    border-radius: $border-radius-cb;

    &:hover{
      color: $primary-color;
    }
    svg{
      margin-right: $offset-l;
    }
  }
}
.body{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .h2{
    position: relative;
    font-family: "Lato", sans-serif;
    font-size: $title;
    font-weight: $font-bold;
    padding: $offset-xxl 0 $offset-l;

    &:after{
      position: absolute;
      content: '';
      height: 3px;
      width: 70px;
      background-color: $default-color;
      bottom: -5px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
  .chapter{
    font-family: "Lato", sans-serif;
    font-weight: $font-semibold;
    font-size: $large;
  }
  .subtitle{
    font-family: "Lato", sans-serif;
    font-weight: $font-semibold;
    font-size: $regular;
  }
  .title {
    font-family: "Lato", sans-serif;
    font-size: $regular;
    background-color: transparentize($default-color, .95);
    padding: $offset-m $offset-l;
    font-weight: $font-bold;
    margin-bottom: $offset-l;
    margin-top: $offset-xxl;
  }
  .txt{
    font-family: "Lato", sans-serif;
    font-size: $small;
    text-indent: $offset-l;
    text-align: justify;
  }
}